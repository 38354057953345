import React, { useRef, useState, useEffect, type KeyboardEvent, type MouseEvent } from 'react';

import { withPrefix } from 'gatsby';
import usePathPrefix from '../../util/hooks/usePathprefix';

import { Play, Pause } from '@carbon/react/icons';

import cx from 'clsx';
import * as css from './Video.module.scss';

interface PropsBase {
    autoPlay?: boolean;
    vimeoId?: string;
    src?: string;
    title?: string;
}
interface VideoPropsVimeo extends PropsBase {
    vimeoId: string;
}
interface VideoPropsLocal extends PropsBase {
    src: string;
    poster?: string;
    children?: ReactElement;
    muted?: boolean;
}

export type VideoProps = VideoPropsVimeo | VideoPropsLocal;

export default function Video(props: VideoProps) {
    const { autoPlay, vimeoId, title, src, poster, muted } = props as VideoPropsLocal & VideoPropsVimeo;

    const [isPlaying, setIsPlaying] = useState<boolean>(!!autoPlay);
    const pathPrefix = usePathPrefix();
    const videoRef = useRef<HTMLVideoElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const buttonClassName = cx(css.videoButton, isPlaying && css.videoIsPlaying);

    // React doesn't handle the muted attribute well
    // https://github.com/facebook/react/issues/10389#issuecomment-605689475
    useEffect(() => {
        if (muted && videoRef.current) videoRef.current.setAttribute('muted', '');
    }, [muted]);

    // If a video/poster is imported into an MDX file and provided through
    // a js variable, it will already have the path-prefix.
    //
    // If the src/poster is just a reference to a file in the static directory,
    // then we need to prefix for them.
    const srcContainsPrefix = pathPrefix && src && src.includes(pathPrefix);
    const fixedSrc = srcContainsPrefix ? src : withPrefix(src);

    let fixedPoster: undefined | string;
    if (poster) fixedPoster = pathPrefix && poster.includes(pathPrefix) ? poster : withPrefix(poster);

    if (vimeoId) {
        return (
            <div className={css.videoContainer}>
                <div className={cx(css.video, css.vimeo)}>
                    <div className="embedVideo-container">
                        <iframe
                            allow="autoplay"
                            title={title}
                            src={`https://player.vimeo.com/video/${vimeoId}`}
                            ref={iframeRef}
                            width="640"
                            height="360"
                            allowFullScreen
                            style={{ border: 0 }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    function onClick(e: MouseEvent) {
        const video = videoRef.current;
        if (!video) return;

        e.stopPropagation();
        if (isPlaying) {
            video.pause();
            setIsPlaying(false);
            return;
        }

        return video
            .play()
            .then(() => setIsPlaying(true))
            .catch(error => console.log(error));
    }
    const onEnded = () => setIsPlaying(false);
    function onKeyDown(e: KeyboardEvent) {
        const video = videoRef.current;
        if (!video) return;

        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            if (isPlaying) {
                video.pause();
                setIsPlaying(false);
                return;
            }

            return video
                .play()
                .then(() => setIsPlaying(true))
                .catch(error => console.log(error));
        }
    }

    return (
        <div className={css.videoContainer}>
            <div className={buttonClassName} role="button" onClick={onClick} onKeyDown={onKeyDown} tabIndex={0}>
                {isPlaying ? <Pause size={32} /> : <Play size={32} />}
                <span className="cds--assistive-text" children={isPlaying ? 'Pause' : 'Play'} />
            </div>

            <video
                autoPlay={autoPlay}
                className={css.video}
                ref={videoRef}
                onEnded={onEnded}
                src={fixedSrc}
                poster={fixedPoster}
            />
        </div>
    );
}

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-en-mdx": () => import("./../../../src/pages/index.en.mdx" /* webpackChunkName: "component---src-pages-index-en-mdx" */),
  "component---src-pages-index-es-mdx": () => import("./../../../src/pages/index.es.mdx" /* webpackChunkName: "component---src-pages-index-es-mdx" */),
  "component---src-pages-index-fa-mdx": () => import("./../../../src/pages/index.fa.mdx" /* webpackChunkName: "component---src-pages-index-fa-mdx" */),
  "component---src-pages-index-ru-mdx": () => import("./../../../src/pages/index.ru.mdx" /* webpackChunkName: "component---src-pages-index-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-404-tsx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-404-tsx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-linux-installation-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/agent/linux-installation.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-linux-installation-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-locations-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/agent/locations.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-locations-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-management-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/agent/management.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-management-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-overview-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/agent/overview.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-overview-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-upgrade-uninstallation-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/agent/upgrade-uninstallation.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-upgrade-uninstallation-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-windows-installation-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/agent/windows-installation.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-agent-windows-installation-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-curtailment-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/curtailment.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-curtailment-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-customers-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/customers.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-customers-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-dashboard-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/dashboard.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-dashboard-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-energy-reports-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/energy-reports.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-energy-reports-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-hosting-payments-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/hosting-payments.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-hosting-payments-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-invoices-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/invoices.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-invoices-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-issue-tracker-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/issue-tracker.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-issue-tracker-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-limitations-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/limitations.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-limitations-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-notifications-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/notifications.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-notifications-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-overview-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/overview.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-overview-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-prerequisites-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/prerequisites.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-prerequisites-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-price-tiers-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/price-tiers.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-price-tiers-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-quick-start-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/quick-start.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-quick-start-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-scanner-automation-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/scanner-automation.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-scanner-automation-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-scanner-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/scanner.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-scanner-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-sign-up-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/sign-up.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-sign-up-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-sitemap-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/sitemap.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-sitemap-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-support-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/support.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-support-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-triggers-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/triggers.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-triggers-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-troubleshooting-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/troubleshooting.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-troubleshooting-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-updates-maintenance-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/updates-maintenance.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-updates-maintenance-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-user-interface-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/user-interface.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-user-interface-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-using-components-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/using-components.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-using-components-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-whats-new-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/whats-new.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-whats-new-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-workers-worker-details-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/workers/worker-details.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-workers-worker-details-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-workers-workers-list-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-manager/workers/workers-list.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-manager-workers-workers-list-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-about-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/about.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-about-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-amlogic-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/amlogic.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-amlogic-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-autotuning-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/autotuning.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-autotuning-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-batch-management-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/batch-management.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-batch-management-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-benefits-performance-gallery-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/benefits-performance-gallery.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-benefits-performance-gallery-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-configuration-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/configuration.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-configuration-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-dps-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/dps.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-dps-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-advanced-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/faqs/advanced.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-advanced-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-basics-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/faqs/basics.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-basics-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-configuration-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/faqs/configuration.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-configuration-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-dps-autotuning-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/faqs/dps-autotuning.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-dps-autotuning-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-farm-operators-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/faqs/farm-operators.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-farm-operators-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-license-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/faqs/license.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-license-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-troubleshooting-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/faqs/troubleshooting.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-faqs-troubleshooting-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-installation-install-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/installation/install.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-installation-install-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-installation-uninstall-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/installation/uninstall.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-installation-uninstall-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-installation-upgrade-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/installation/upgrade.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-installation-upgrade-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-about-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-about.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-about-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-bosminer-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-bosminer.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-bosminer-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-changelog-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-changelog.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-changelog-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcui-faqs-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-tool-grpcui/faqs.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcui-faqs-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcui-quick-start-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-tool-grpcui/quick-start.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcui-quick-start-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcurl-faqs-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-tool-grpcurl/faqs.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcurl-faqs-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcurl-overview-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-tool-grpcurl/overview.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcurl-overview-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcurl-quick-start-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-tool-grpcurl/quick-start.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-grpcurl-quick-start-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-postman-faqs-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-tool-postman/faqs.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-postman-faqs-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-postman-overview-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-tool-postman/overview.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tool-postman-overview-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tools-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/papi-tools.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-papi-tools-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-technical-resources-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/technical-resources.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-technical-resources-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-whats-new-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-os/whats-new.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-os-whats-new-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-about-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/about.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-about-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-about-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/about.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-about-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-about-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/about.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-about-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-about-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/about.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-about-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-btc-mining-setup-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/btc-mining-setup.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-btc-mining-setup-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-btc-mining-setup-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/btc-mining-setup.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-btc-mining-setup-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-btc-mining-setup-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/btc-mining-setup.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-btc-mining-setup-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-btc-mining-setup-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/btc-mining-setup.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-btc-mining-setup-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-account-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/account.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-account-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-account-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/account.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-account-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-account-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/account.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-account-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-account-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/account.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-account-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-connection-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/connection.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-connection-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-mining-basics-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/mining-basics.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-mining-basics-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-pool-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/pool.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-pool-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-pool-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/pool.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-pool-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-pool-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/pool.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-pool-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-pool-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/pool.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-pool-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-rewards-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/rewards.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-rewards-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-worker-management-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/worker-management.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-worker-management-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-worker-management-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/worker-management.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-worker-management-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-worker-management-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/worker-management.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-worker-management-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-worker-management-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/faqs/worker-management.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-faqs-worker-management-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-hashrate-specification-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/hashrate-specification.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-hashrate-specification-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-monitoring-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/monitoring.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-monitoring-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-monitoring-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/monitoring.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-monitoring-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-monitoring-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/monitoring.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-monitoring-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-monitoring-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/monitoring.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-monitoring-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-quick-start-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/quick-start.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-quick-start-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-quick-start-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/quick-start.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-quick-start-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-quick-start-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/quick-start.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-quick-start-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-quick-start-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/quick-start.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-quick-start-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-rewards-and-payouts-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/rewards-and-payouts.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-rewards-and-payouts-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-rewards-and-payouts-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/rewards-and-payouts.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-rewards-and-payouts-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-stratum-v-2-manual-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/stratum-v2-manual.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-stratum-v-2-manual-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-stratum-v-2-manual-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/stratum-v2-manual.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-stratum-v-2-manual-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-stratum-v-2-manual-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/stratum-v2-manual.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-stratum-v-2-manual-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-stratum-v-2-manual-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/stratum-v2-manual.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-stratum-v-2-manual-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-user-accounts-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/user-accounts.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-user-accounts-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-user-accounts-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/user-accounts.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-user-accounts-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-user-accounts-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/user-accounts.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-user-accounts-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-user-accounts-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-pool/user-accounts.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-pool-user-accounts-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-cooling-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/cmd-cooling.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-cooling-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-firmware-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/cmd-firmware.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-firmware-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-miner-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/cmd-miner.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-miner-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-scan-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/cmd-scan.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-scan-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-system-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/cmd-system.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-system-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-tuner-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/cmd-tuner.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-cmd-tuner-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-introduction-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/introduction.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-introduction-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-limitations-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/limitations.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-limitations-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-overview-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/overview.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-overview-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-running-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/running.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-running-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-support-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/support.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-support-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-troubleshooting-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/troubleshooting.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-troubleshooting-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-whats-new-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/braiins-toolbox/whats-new.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-braiins-toolbox-whats-new-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-about-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/about.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-about-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-dashboards-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/dashboards.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-dashboards-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-install-uninstall-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/install-uninstall.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-install-uninstall-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-limitations-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/limitations.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-limitations-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-metrics-and-labels-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/metrics-and-labels.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-metrics-and-labels-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-promql-examples-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/promql-examples.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-promql-examples-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-setup-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/setup.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-setup-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-structure-your-farm-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/structure-your-farm.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-structure-your-farm-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-troubleshooting-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/troubleshooting.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-troubleshooting-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-whats-new-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-monitor/whats-new.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-monitor-whats-new-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-about-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/about.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-about-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-configuration-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/configuration.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-configuration-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-faqs-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/faqs.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-faqs-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-features-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/features.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-features-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-install-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/install.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-install-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-introduction-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/introduction.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-introduction-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-limitations-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/limitations.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-limitations-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-monitoring-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/monitoring.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-monitoring-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-portal-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/portal.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-portal-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-troubleshooting-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/farm-proxy/troubleshooting.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-farm-proxy-troubleshooting-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-about-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/about.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-about-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-about-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/about.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-about-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-about-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/about.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-about-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-about-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/about.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-about-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-bitcoin-mining-stats-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/bitcoin-mining-stats.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-bitcoin-mining-stats-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-bitcoin-mining-stats-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/bitcoin-mining-stats.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-bitcoin-mining-stats-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-bitcoin-mining-stats-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/bitcoin-mining-stats.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-bitcoin-mining-stats-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-bitcoin-mining-stats-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/bitcoin-mining-stats.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-bitcoin-mining-stats-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-inputs-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/cost-to-mine/inputs.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-inputs-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-inputs-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/cost-to-mine/inputs.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-inputs-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-outputs-and-charts-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/cost-to-mine/outputs-and-charts.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-outputs-and-charts-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-outputs-and-charts-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/cost-to-mine/outputs-and-charts.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-outputs-and-charts-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-overview-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/cost-to-mine/overview.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-overview-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-overview-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/cost-to-mine/overview.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-overview-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-saving-and-sharing-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/cost-to-mine/saving-and-sharing.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-saving-and-sharing-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-saving-and-sharing-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/cost-to-mine/saving-and-sharing.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-cost-to-mine-saving-and-sharing-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-mining-pool-stats-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/mining-pool-stats.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-mining-pool-stats-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-mining-pool-stats-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/mining-pool-stats.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-mining-pool-stats-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-mining-pool-stats-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/mining-pool-stats.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-mining-pool-stats-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-mining-pool-stats-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/mining-pool-stats.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-mining-pool-stats-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-inputs-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/profitability/inputs.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-inputs-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-inputs-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/profitability/inputs.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-inputs-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-outputs-and-charts-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/profitability/outputs-and-charts.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-outputs-and-charts-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-outputs-and-charts-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/profitability/outputs-and-charts.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-outputs-and-charts-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-overview-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/profitability/overview.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-overview-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-overview-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/profitability/overview.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-overview-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-saving-and-sharing-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/profitability/saving-and-sharing.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-saving-and-sharing-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-saving-and-sharing-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/profitability/saving-and-sharing.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-profitability-saving-and-sharing-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-public-api-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/public-api.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-public-api-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-public-api-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/public-api.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-public-api-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-public-api-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/public-api.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-public-api-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-public-api-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/mining-insights/public-api.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-mining-insights-public-api-ru-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-terms-and-policies-en-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/terms-and-policies.en.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-terms-and-policies-en-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-terms-and-policies-es-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/terms-and-policies.es.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-terms-and-policies-es-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-terms-and-policies-fa-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/terms-and-policies.fa.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-terms-and-policies-fa-mdx" */),
  "component---src-templates-default-tsx-content-file-path-src-pages-terms-and-policies-ru-mdx": () => import("./../../../src/templates/Default.tsx?__contentFilePath=/opt/build/repo/src/pages/terms-and-policies.ru.mdx" /* webpackChunkName: "component---src-templates-default-tsx-content-file-path-src-pages-terms-and-policies-ru-mdx" */)
}


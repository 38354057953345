import { type ComponentProps } from 'react';
import { MDXProvider } from '@mdx-js/react';

import { P, H1, H2, H3, H4, H5, Ul, Ol, Li, Blockquote } from '../markdown';
import PageTable from '../PageTable';
import Code from '../Code';
import PageDescription from '../PageDescription';
import Video from '../Video';
import Caption from '../Caption';
import ResourceCard from '../ResourceCard';
import ArticleCard from '../ArticleCard';
import Aside from '../Aside';
import FeatureCard from '../FeatureCard';
import ImageCard from '../ImageCard';
import InlineNotification from '../InlineNotification';
import { Row, Column, Grid } from '../Grid';
import { AnchorLink, AnchorLinks } from '../AnchorLinks';
import { Tab, Tabs } from '../Tabs';
import Link from '../Link';
import { Accordion, AccordionItem } from '../Accordion';
import Title from '../Title';
import { MiniCard, CardGroup } from '../MiniCard';
import Sup from '../markdown/Sup';
import SquareCard from '../SquareCard';
import ExpressiveListContainer from '../ExpressiveListContainer';
import ExpressiveList from '../ExpressiveList';

export default {
    Accordion,
    AccordionItem,
    AnchorLink,
    AnchorLinks,
    ArticleCard,
    Aside,
    Caption,
    CardGroup,
    Column,
    ExpressiveList,
    ExpressiveListContainer,
    FeatureCard,
    Grid,
    ImageCard,
    InlineNotification,
    MiniCard,
    PageDescription,
    ResourceCard,
    Row,
    SquareCard,
    Tab,
    Tabs,
    Title,
    Video,
    a: Link,
    blockquote: Blockquote,
    /**
     * MDX-2 converts code blocks into a `pre > code` React tree,
     * where the nested `code` has a className with the language name.
     *
     * We use the `pre` because this identifies the code-fence,
     * whereas `code` matches both code-fence and inline code.
     */
    pre: Code,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    li: Li,
    ol: Ol,
    p: P,
    sup: Sup,
    table: PageTable,
    ul: Ul,
} as any as ComponentProps<typeof MDXProvider>['components'];
